.HomePage {
  .main-banner {
    background-size: cover;
    width: calc(100% - 160px);
    height: calc(100vh - 180px);
    min-height: 400px;
    margin: 24px auto 0;
    border-radius: 40px;
    overflow: hidden;

    @media (max-width: 768px) {
      & {
       width: 95%;
      }
    }
  }

  .banner-text{
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(103 94 80 / 18%);

    .banner-wrapper{
      background: linear-gradient(180deg, rgba(25, 47, 58, 0) -45.65%, rgba(25, 47, 58, 0.0395005) -40.72%, rgba(25, 47, 58, 0.947917) 99.99%, #192F3A 100%);
    }

    h1{
      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 60px;
      line-height: 117px;
      color: #FFFFFF;
      max-width: 500px;
      text-align: center;

      @media (max-width: 650px) {
        & {
          font-size: 45px;
        }
      }
    }
    h2 {
      font-family: 'Playfair Display', serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      line-height: 117px;
      color: #ff0000;
      max-width: 500px;
      text-align: center;

      @media (max-width: 650px) {
        & {
          font-size: 25px;
        }
      }
    }
  }
}

.booking-form{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -120px auto 105px;
  background-color: #ffffffcf;
  width: fit-content;
  padding: 40px 80px;
  border-radius: 40px;
  box-shadow: var(--box-shadow);

  @media (max-width: 1200px) {
    & {
      padding: 20px 40px;
      margin: 0;
      width: 100%;
      background-color: transparent;

      &-wrapper{
        background-color: transparent!important;
        padding: 0!important;

        .ant-picker, .ant-select {
          margin-right: 0!important;
        }
      }
    }
  }

  &-wrapper{
    display: flex;
    align-items: center;
    height: 160px;
    background-color: var(--color-brown);
    border-radius: 20px;
    padding: 0 45px;

    @media (max-width: 1024px) {
      & {
        flex-direction: column;
        align-items: center;
      }
    }

    .ant-picker{
      margin-right: 40px;
      width: 205px;
      height: 65px;
      border-radius: 10px;

      .ant-picker-input > input{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 21px;

        color: #192F3A;

        &::placeholder{
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          color: #192F3A;
        }
      }
    }

    .ant-select{
      margin-right: 40px;
      width: 205px!important;
      height: 65px;
      border-radius: 10px;

      .ant-select-selector{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100% !important;
        border-radius: 10px!important;

        .ant-select-selection-item{
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 21px;

          color: #192F3A;
        }
      }
    }

    .ant-btn{
      width: 175px;
      height: 65px;
      background: var(--color-blue-dark);
      border-radius: 10px;
      border: transparent;

      span{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 700;
        font-size: 17px;
        line-height: 21px;

        color: #FFFFFF;
      }

      &:hover{
        border: transparent;
        background-color: rgba(25,47,58,0.5);
      }
      &:focus{
        border: transparent;
        background-color: rgba(25,47,58,0.5);
      }
    }
  }
}

.flats-block{
  padding: 0 90px;
  margin-top: 100px;

  @media (max-width: 768px) {
    & {
      padding: 0 20px;
      margin-top: 80px;
    }
  }


  .flats-block-wrapper {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    cursor: pointer;

    .flat-block {
      display: flex;
      height: 475px;
      align-items: flex-end;
      justify-content: center;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      border-radius: 40px;
      overflow: hidden;
      transition: var(--transition);

      &:hover {
        box-shadow: var(--box-shadow);
      }

      &:hover .flat-block-description {
        height: 100%;
      }

      .flat-block-description{
        position: absolute;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 205px;
        width: 100%;
        transition: var(--transition);
        background: linear-gradient(180deg, rgba(25, 47, 58, 0) -45.65%, rgba(25, 47, 58, 0.0395005) -40.72%, rgba(25, 47, 58, 0.947917) 99.99%, #192F3A 100%);

        h3{
          text-align: center;
          white-space: break-spaces;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #FFFFFF;
        }
        span{
          text-align: center;
          white-space: break-spaces;
          font-family: 'Montserrat';
          font-style: normal;
          font-weight: 500;
          font-size: 17px;
          line-height: 21px;
          color: #FFFFFF;
        }
      }
    }
  }
}

.showMore {
  margin-top: 55px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    background: var(--color-brown);
    width: 200px;
    height: 60px;
    font-family: 'Montserrat', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    color: #FFFFFF;
    border-radius: 24px;
    border: none;

    &:hover {
      color: #FFFFFF;
      background: var(--color-brown-darker);
    }
  }
}
.contact-text{
  margin-top: 105px;
  padding: 0 90px;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    & {
      margin-top: 50px;
      padding: 0 20px;
    }
  }

  .line {
    width: 60px;
    background-color: #192F3A;
    height: 1.5px;
  }

  .text{
    margin-left: 55px;
    display: flex;
    flex-direction: column;

    span{
      font-family: 'Montserrat',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 25px;
      line-height: 38px;
      color: #192F3A;
    }
  }
}

.contact-form{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 455px;
  margin-top: 100px;

  @media (max-width: 768px) {
    & {
      height: 255px;
      margin-top: 50px;
    }
  }

  .contact-form-wrapper{
    display: flex;
    align-items: center;

    @media (max-width: 650px) {
      & {
        flex-direction: column;

        input {
          width: 250px !important;
          border-radius: 20px;
        }

        button{
          width: 250px !important;
          border-radius: 20px;
          margin-top: 20px;
        }
      }
    }

    input{
      background: #FFFFFF;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      width: 380px;
      height: 60px;
      font-family: 'Montserrat';
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 38px;
      padding: 0 20px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;

      ::placeholder{
        color: rgba(99, 85, 85, 0.95);
      }
    }

    button{
      width: 163px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 1.5rem;
      transition: var(--transition);

      background: #192F3A;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      font-family: 'Montserrat',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 38px;
      color: #FFFFFF;
      border: none;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;

      &:hover {
        gap: 2rem;
      }

      img {
        width: 15px;
        height: 20px;
      }
    }
  }

  img {
    border-radius: 40px;
    box-shadow: var(--box-shadow);
  }
}
.feedback-block{
  align-items: start;
  padding: 0 90px;
  margin-top: 70px;
  margin-bottom: 145px;
  height: auto;
  cursor: pointer;

  @media (max-width: 768px) {
    & {
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 0 20px;
    }
  }

  .feedback-title {
    display: flex;
    align-items: center;
    margin-bottom: 70px;

    @media (max-width: 768px) {
      & {
        margin-bottom: 50px;
      }
    }

    .line {
      width: 60px;
      background-color: #192F3A;
      height: 1.5px;
    }

    .text{
      margin-left: 55px;
      display: flex;
      flex-direction: column;

      span{
        font-family: 'Montserrat',sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        color: #192F3A;
      }
    }
  }
  .feedback-wrapper {
    height: auto;
    position: relative;

    .swiper {
      width: 100%;
      height: 390px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .swiper-wrapper {
      height: 390px;
      margin-bottom: 60px;
    }

    .swiper-pagination{
      bottom: 0;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;
      padding: 36px;
      border-radius: 40px;
      box-shadow: var(--box-shadow);

      /* Center slide text vertically */
      display: -webkit-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .feedback-author {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 15px;

      .withoutImageAuthor{
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--color-blue-dark);
        color: #FFFFFF;
      }
      img, .withoutImageAuthor{
        width: 60px;
        height: 60px;
        margin-right: 25px;
        border-radius: 50%;
      }

      span {
        font-family: 'Montserrat',sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 17px;

        color: #000000;
      }
    }

    .feedback-text {
      font-family: 'Montserrat',sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 137.9%;
      text-align: start;
      color: #858585;

    }

    .feedback-rate {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 100%;

      img{
        width: 15px;
        height: 16px;
        margin-right: 6px;
      }
    }
  }
}
