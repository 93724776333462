body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  background-color: #FFFFFF;
}

:root {
  --color-brown: #776e60;
  --color-brown-darker: #5d5345;
  --color-blue-dark: #192F3A;

  --transition: 0.3s;

  --box-shadow: 0 0 14px -6px rgb(103 94 80 / 18%);
  --box-shadow-darker: 0 0 20px -6px rgb(103 94 80 / 18%);
}

button,
a {
  transition: var(--transition);
}

.modal-success-block {
  .ant-message-notice-content {
    height: 220px;
    max-width: 400px;

    .ant-message-success{
      display: flex;
      flex-direction: column;

      .anticon{
        font-size: 55px;
        margin-bottom: 20px;
      }

      & span {
        font-family: "Montserrat", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        color: #192F3A;
      }
    }
  }
}
