.apartments-page{
  margin-bottom: 150px;
  padding-top: 80px;

  .booking-form-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 225px;
    margin: 105px 0 80px 0;

    @media (max-width: 1024px) {
      & {
        margin:0 0 40px 0!important;
      }
    }
  }

  .apartments-list{
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 0 90px;
    gap: 4rem;

    @media (max-width: 1024px) {
      & {
        flex-direction: column;
        align-items: center;
        padding: 0 20px;

        .apartment {
          width: 100% !important;
          height: 100% !important;
          flex-direction: column;
        }
      }
    }

    .apartment{
      display: flex;
      height: 350px;
      width: calc(50% - 2rem);
      background: #FFFFFF;
      border-radius: 40px;
      box-shadow: var(--box-shadow-darker);
      overflow: hidden;

      .apartment-photo{
        flex: 1 1 40%;

        button {
          width: 100%;
          height: 100%;
          padding: 0;
          border: none;
          background: transparent;
          cursor: pointer;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

        .apartment-description{
          flex: 1 1 60%;
          display: flex;
          flex-direction: column;
          padding: 30px;

          button{
            font-family: 'Montserrat',sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 26px;
            line-height: 34px;
            border: none;
            background: transparent;
            color: #000000;
            cursor: pointer;
          }

          p{
            font-family: 'Montserrat',sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 24px;
            letter-spacing: 0.06em;
            max-height: 125px;
            overflow: hidden;
            color: #919191;
            margin-top: 35px;
            margin-bottom: 55px;
          }

          .navigation{
            display: flex;
            width: 100%;
            justify-content: space-between;

            .view-more{
              display: flex;
              align-items: center;
              border: none;
              background-color: transparent;

              span{
                font-family: 'Montserrat',sans-serif;
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                line-height: 30px;
                color: #192F3A;
                text-decoration: underline;
                text-underline-offset: 7px;
                cursor: pointer;
              }

              img{
                width: 20px;
                height: 20px;
                margin-right: 20px;
              }
            }

            .map{
              width: 90px;
              height: 36px;
              border: 1px solid var(--color-brown);
              background-color: #FFFFFF;
              font-family: 'Montserrat',sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 15px;
              line-height: 21px;
              color: var(--color-brown-darker);
              cursor: pointer;
              border-radius: 20px;
              text-transform: uppercase;
            }
          }
        }
      }
    }
  }

  .apartments-district {
    padding: 0 90px;
    gap: 4rem;

    @media (max-width: 768px) {
      & {
        padding: 0 20px;
        margin-top: 80px;
      }
    }

    .flats-block-wrapper {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
      cursor: pointer;

      .flat-block {
        display: flex;
        height: 475px;
        align-items: flex-end;
        justify-content: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        border-radius: 40px;
        overflow: hidden;
        box-shadow: var(--box-shadow);

        .flat-block-description{
          position: absolute;
          bottom: 0;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          width: 100%;
          transition: var(--transition);
          background: linear-gradient(180deg, rgba(25, 47, 58, 0) -45.65%, rgba(25, 47, 58, 0.0395005) -40.72%, rgba(25, 47, 58, 0.947917) 99.99%, #192F3A 100%);

          h3{
            text-align: center;
            white-space: break-spaces;
            font-family: 'Montserrat',sans-serif;
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 24px;
            color: #FFFFFF;
          }
          span{
            text-align: center;
            white-space: break-spaces;
            font-family: 'Montserrat',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 21px;
            color: #FFFFFF;
          }
        }
      }
    }
  }

  .show-more-apartments{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    button {
      margin-top: 60px;
      background: var(--color-brown);
      width: 200px;
      height: 60px;
      font-family: 'Montserrat', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 17px;
      line-height: 21px;
      color: #FFFFFF;
      border-radius: 24px;
      border: none;
      cursor: pointer;

      &:hover {
        color: #FFFFFF;
        background: var(--color-brown-darker);
      }
    }
  }
