.map-page{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
  padding-top: 100px;

  .map-title{
    margin: 55px 0 55px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .title{
      background: #625746;
      border-radius: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 365px;
      height: 70px;

      span{
        font-family: 'Playfair Display',sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 40px;
        line-height: 53px;

        color: #FFFFFF;
      }
    }
  }

  .map-wrapper{
    width: 1180px;
    height: 740px;
    border: 5px solid rgba(98, 87, 70, 0.75);
  }
}

.info-window {
  display: flex;

  &-mainImage {
     img{
       max-height: 150px;
       max-width: 150px;
     }
  }

  &-descriptions {
    display: flex;
    flex-direction: column;
    padding-left: 10px;

    h2{
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 26px;
      line-height: 34px;
      border: none;
      background: transparent;
      color: #000000;
      cursor: pointer;
    }

    span {
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 24px;
      letter-spacing: 0.06em;
      color: #919191;
    }

    button {
      width: 150px;
      height: 42px;
      border: none;
      background: var(--color-brown);
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: "Montserrat", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 19px;
      color: #FFFFFF;
      cursor: pointer;
      border-radius: 20px;
    }
  }
}
