#header {
    position: sticky;
    top: 0;
    z-index: 9;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    justify-content: space-between;
    align-items: center;
    height: 90px;
    background-color: #fff;
    padding: 0.5rem 1.5rem 0;

    @media (max-width: 1119px) {
        & {
            grid-template-columns: 1fr 1fr;
        }
    }

    .hamburger-menu {
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;

        img {
            width: 40px;
            height: 40px;
        }
    }

    .logo-wrapper{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex: 1 1 50%;
        max-width: 200px;

        img {
            width: 200px;
        }
    }

    .right-panel {
        display: flex;
        justify-content: flex-end;
        gap: 1.5rem;
    }

    #main-book-button {
        width: 140px;
        height: 48px;
        background: var(--color-brown);
        border-radius: 7px;
        border: none;
        font-family: 'Montserrat', serif;
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 27px;
        color: #FFFFFF;
        cursor: pointer;
    }
}

.language-button {

    select {
        height: 48px;
        border: 1px solid var(--color-brown);
        background: transparent;
        border-radius: 7px;
        font-family: "Montserrat", serif;
        font-style: normal;
        font-weight: 700;
        font-size: 13px;
        line-height: 27px;
        color: var(--color-brown);
        cursor: pointer;
    }

    button{
        display: flex;
        justify-content: center;
        align-items: center;
        background: transparent;
        width:100%;
        height: 100%;
        border: none;
        font-family: 'Montserrat', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #FFFFFF;
        cursor: pointer;

        .arrow-language{
            width: 9px;
            height: 5px;
            margin-left: 5px;
        }
    }
}

.navigation{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 1 50%;

    .navigation-list{
        display: flex;
        list-style: none;
        padding: 0;
        margin: 0;
        gap: 1.5rem;

        li{
            a{
                cursor: pointer;
                font-family: 'Montserrat', sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 21px;
                line-height: 26px;
                color: #192F3A;
            }

            &.active{
                font-weight: 600;
            }

            &:last-child{
                margin-right: 0;
            }
        }
    }
}

.navigation-mobile {
    background-color: #FFFFFF;
    position: sticky;
    top: 90px;
    padding: 0 40px 40px;
    box-shadow: 0 5px 12px -6px rgb(103 94 80 / 18%);
    z-index: 9;

    .navigation {
        display: block;
    }

    .navigation-list {
        flex-direction: column;
    }
}
