.divider-wrapper{
    position: relative;

    .divider-line-wrapper{
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      .divider-line{
        width: 100%;
        height: 1px;
        border: 1px solid #858585;
      }
    }
  }

.title{
    display: flex;
    justify-content: center;
    position: relative;

    .bg-light{
      background-color: #FFFFFF;
      padding: 0 20px;

      span{
        font-family: 'Playfair Display',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 53px;
        color: #192F3A;
      }
    }
  }
