.anreise-page{
  margin: 0 0 40px 0;
  padding: 100px 120px 40px;
  min-height: calc(100vh - 360px);

  @media (max-width: 1100px) {
    & {
      padding: 40px 40px 40px;
    }
  }

  @media (max-width: 600px) {
    & {
      padding: 40px 24px 40px;
    }
  }

  .anreise-title {
    font-size: 48px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 800;
    color: var(--color-blue-dark);
    margin-bottom: 40px;
  }

  .anreise-block{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-family: 'Montserrat',sans-serif;
    font-style: normal;
    font-size: 20px;
    line-height: 24px;
    color: #000000;

    .title{
      margin-bottom: 30px;
    }
    .price{
      padding: 20px 10px;
      border: 1px solid #919191;
      margin-bottom: 35px;
      h4 {
        margin-bottom: 20px;
        font-family: 'Montserrat',sans-serif;
        font-weight: 600;
      }
    }
    .contact-button{
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      a{
        font-family: 'Montserrat',sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 21px;
        color: var(--color-blue-dark);
      }
      button:last-child{
        margin-right: 0;
      }
    }
  }
  .banner-block{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 55px auto;
    background-color: #FFFFFF;
    border-radius: 40px;
    width: fit-content;
    padding: 40px;

    span{
      max-width: 900px;
      font-family: 'Playfair Display',sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 43px;
      text-align: center;
      color: var(--color-brown);
    }
  }
}
