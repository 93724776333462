.contact-page{
  margin: 55px 0 40px 0;
  padding: 0 120px;

  .contact-block{
    display: flex;
    margin-top: 40px;

    .left-side{
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;

      span{
        max-width: 530px;
        font-family: 'Montserrat',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }

      .contact-number{
        display: flex;
        flex-direction: column;
        span{
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 26px;
          color: #192F3A;
        }
        span.bold{
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
    }

    .right-side{
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      span{
        font-family: 'Montserrat',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 21px;
        line-height: 26px;
        color: #192F3A;
      }
      span.bold{
        font-weight: 700;
        margin-bottom: 10px;
      }
    }
  }
}
