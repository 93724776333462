footer{
    display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--color-blue-dark);
    color: #FFFFFF;
    padding: 90px 90px 45px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;

    @media (max-width: 768px) {
        & {
            padding: 20px 20px 10px;
        }
    }

    .wrapper {
        width: 100%;
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        @media (max-width: 760px) {
            & {
                flex-direction: column;
                align-items: center;
            }
        }

        .footer-main {
            display: flex;
            gap: 2rem;

            @media (max-width: 1020px) {
                & {
                    flex-direction: column;
                }
            }
        }

        .footer-contact{
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            h4 {
                font-family: 'Montserrat',sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 21px;
                line-height: 26px;
                color: #FFFFFF;
            }

            p{
                font-family: 'Montserrat',sans-serif;
                font-size: 16px;
                line-height: 26px;

                a{
                    color: #FFFFFF;
                    margin-left: 10px;
                }
            }
        }

        .footer-navigation {
            display: flex;
            gap: 1rem;

            ul {
                list-style: none;
                padding-inline-start: 0;

                li{
                    margin-bottom: 20px;
                    font-family: 'Montserrat',sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 22px;
                    color: #FFFFFF;
                    cursor: pointer;

                    a {
                        color: #FFFFFF;
                        background-color: transparent;
                        border: none;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        cursor: pointer;
                    }
                }

                li:last-child{
                    margin-bottom: 0px;
                }
            }

        }

        .footer-map{
            margin-left: auto;
            height: 300px;
            width: 540px;

            @media (max-width: 1260px) {
                & {
                    width: 300px
                }
            }

            @media (max-width: 760px) {
                & {
                    width: 100%;
                }
            }
        }
    }

    .social-links {
        display: flex;
        gap: 12px;
    }
}
