.contact-page{
  margin: 55px 0 40px 0;
  padding: 100px 120px 0;
  min-height: calc(100vh - 360px);
  @media (max-width: 768px) {
    & {
      text-align: center;
      padding: 0 20px!important;
    }
  }

  .contact-title {
    font-size: 48px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 800;
    color: var(--color-blue-dark);
  }

  .contact-block{
    display: flex;
    margin-top: 40px;

    @media (max-width: 1024px) {
      & {
        flex-direction: column;
        align-items: center;

        .left-side {
          margin-bottom: 40px;
        }
      }
    }

    .left-side{
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;

      span{
        max-width: 530px;
        font-family: 'Montserrat',sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #000000;
      }

      .line {
        max-width: 450px;
        border: 1px solid #858585;
        margin: 30px 0;
      }

      .contact-number{
        display: flex;
        flex-direction: column;
        a{
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 21px;
          line-height: 26px;
          color: #192F3A;
        }
        span.bold{
          font-weight: 700;
          margin-bottom: 10px;
        }
      }
    }

    .right-side{
      flex: 1 1 50%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .form-wrapper{
        max-width: 355px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .input-name,.input-email {
          width: 355px;
          height: 60px;
          padding-left: 30px;
          margin-bottom: 20px;
          border: 1px solid #000000;
          border-radius: 20px;
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          color: #A8A8A8;
          box-shadow: none;

          @media (max-width: 768px) {
            & {
              width: 300px;
            }
          }
        }
        .text-area {
          max-width: 355px;
          height: 105px;
          border: 1px solid #000000;
          border-radius: 20px;
          box-shadow: none;
          margin-bottom: 20px;
        }
        .checkbox{
          margin-bottom: 20px;

          .checkbox-text{
            font-family: 'Montserrat',sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            color: #A8A8A8;
          }
        }
        .send-form{
          width: 100%;
          height: 60px;
          background: #192F3A;
          border-radius: 20px;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
          color: #FFFFFF;

          span {
            font-family: 'Montserrat',sans-serif;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
