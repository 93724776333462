.detailed-apartment-page{
  margin: 85px 0 0 0;
  padding: 0 115px 60px 95px;

  @media (max-width: 1024px) {
    & {
      padding: 0 20px;
    }
  }
  @media (max-width: 768px) {
    & {
      padding: 0;
      margin: 0;
    }
  }

  h1{
    font-size: 48px;
    font-family: 'Montserrat',sans-serif;
    font-weight: 800;
    color: var(--color-blue-dark);

    @media (max-width: 768px) {
      & {
        padding: 0 10px;
        font-size: 25px;
      }
    }
  }

  .detailed-block-wrapper{
    display: flex;

    @media (max-width: 1024px) {
      & {
        flex-direction: column;
      }
    }

    .media-block{
      flex: 1 1 65%;
      display: flex;
      flex-direction: column;
      width: 65%;

      @media (max-width: 1024px) {
        & {
          width: 100%;
        }
      }

      .swiper {
        width: 100%;
        height: 100%;
      }

      .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        border-radius: 28px;
        overflow: hidden;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 28px;
      }

      body {
        background: #000;
        color: #000;
      }

      .swiper {
        width: 100%;
        height: 300px;
        margin-left: auto;
        margin-right: auto;
      }

      .swiper-slide {
        background-size: cover;
        background-position: center;
      }

      .mySwiper2 {
        height: 560px;
        width: 100%;

        @media (max-width: 768px) {
          & {
            height: 400px;
          }
        }
      }

      .mySwiper {
        height: 20%;
        box-sizing: border-box;
        padding: 10px 0;

        @media (max-width: 1250px) {
          & {
            flex-direction: column;
            align-items: center;
            margin-top: 40px;
            margin-bottom: 40px;
          }
        }
      }

      .mySwiper .swiper-slide {
        width: 25%;
        height: 100%;
        opacity: 0.4;

        @media (max-width: 768px) {
          img {
            height: 128px;
          }
        }
      }

      .mySwiper .swiper-slide-thumb-active {
        opacity: 1;
      }

      .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media (max-width: 768px) {
          :global(.mySwiper) {
            height: 128px;
          }
        }
      }
    }
    .description-block{
      flex: 1 1 35%;
      padding: 0 0 0 60px;

      @media (max-width: 1024px) {
        & {
          padding: 0 20px;
          margin-top: 20px;
        }
      }

      .label-block{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 50px;

        .label{
          display: flex;
          justify-content: center;
          align-items: center;
          width: 77px;
          height: 77px;
          margin-right: 30px;
          background: #FFFFFF;
          border-radius: 20px;
          box-shadow: var(--box-shadow-darker);
        }
      }

      .description{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .title{
          margin-bottom: 25px;
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 22px;
          line-height: 27px;
        }

        .description-text{
          margin-bottom: 25px;
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: 0.06em;
          color: #919191;
        }

        .people-count{
          display: flex;
          margin-bottom: 40px;

          img {
            margin-right: 10px;
          }

          span{
            font-family: 'Montserrat',sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 18px;
          }
        }

        .price-wrapper {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 1rem;
          margin-bottom: 20px;

          @media (max-width: 1250px) {
            & {
              flex-direction: column;
              align-items: center;
            }
          }
        }

        .pricing{
          border-radius: 5px;
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 800;
          font-size: 32px;
          line-height: 17px;
          color: var(--color-blue-dark);
          white-space: nowrap;
        }

        .check-available{
          width: 184px;
          height: 42px;
          border: none;
          background: var(--color-brown);
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: 'Montserrat',sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #FFFFFF;
          cursor: pointer;
          border-radius: 20px;
        }
      }
    }
  }
  .detailed-contact-block{
    margin-top: 85px;
    margin-bottom: 85px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;

    @media (max-width: 1250px) {
      & {
        flex-direction: column;
        align-items: center;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }

    .input-email{
      width: 355px;
      height: 60px;
      border: 1px solid #000000;
      border-radius: 20px;
      margin-right: 45px;

      @media (max-width: 1024px) {
        & {
          width: 280px;
          margin-right: 0;
        }
      }
    }
    button{
      width: 355px;
      height: 60px;
      background: #192F3A;
      border-radius: 20px;
      border: none;
      font-family: 'Montserrat',sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 27px;
      color: #FFFFFF;

      @media (max-width: 1024px) {
        & {
          width: 280px;
        }
      }
    }
  }
}
