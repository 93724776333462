@keyframes animation-item1 {
    0% {transform: translate(0, 0);
        border-radius: 10% 10% 100% 10%;}
    8.33%, 25% {transform: translate(0, 112.5%);
        border-radius: 10% 100% 10% 10%;}
    33.33%, 50% {transform: translate(112.5%, 112.5%);
        border-radius: 10% 100% 10% 10%;}
    58.33%, 75% {transform: translate(112.5%, 0);
        border-radius: 10% 10% 100% 10%;}
    83.33%, 100% {transform: translate(0, 0);
        border-radius: 10% 10% 100% 10%;}
}

@keyframes animation-item2 {
    0% {transform: translate(0, 0);
        border-radius: 10% 10% 100% 10%;}
    8.33%, 25% {transform: translate(-112.5%, 0);
        border-radius: 10% 10% 100% 10%;}
    33.33%, 50% {transform: translate(-112.5%, 112.5%);
        border-radius: 10% 100% 10% 10%;}
    58.33%, 75% {transform: translate(0, 112.5%);
        border-radius: 10% 100% 10% 10%;}
    83.33%, 100% {transform: translate(0, 0);
        border-radius: 10% 10% 100% 10%;}
}


@keyframes animation-item3 {
    0%, 100% {transform: translate(0, 0);
        border-radius: 10% 100% 10% 10%;}
    8.33%, 25% {transform: translate(0, -112.5%);
        border-radius: 10% 10% 100% 10%;}
    33.33%, 50% {transform: translate(-112.5%, -112.5%);
        border-radius: 10% 10% 100% 10%;}
    58.33%, 75% {transform: translate(-112.5%, 0);
        border-radius: 10% 100% 10% 10%;}
    83.33%, 100% {transform: translate(0, 0);
        border-radius: 10% 100% 10% 10%;}
}

@keyframes animation-item4 {
    0%, 100% {transform: translate(0, 0);
        border-radius: 10% 100% 10% 10%;}
    8.33%, 25% {transform: translate(112.5%, 0);
        border-radius: 10% 100% 10% 10%;}
    33.33%, 50% {transform: translate(112.5%, -112.5%);
        border-radius: 10% 10% 100% 10%;}
    58.33%, 75% {transform: translate(0, -112.5%);
        border-radius: 10% 10% 100% 10%;}
    83.33%, 100% {transform: translate(0, 0);
        border-radius: 10% 100% 10% 10%;}
}
